import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase/app"; 
import 'firebase/database'
import axios from 'axios'

Vue.use(Vuex);
Vue.use(require('vue-moment')); // move this somewhere else maybe?

export default new Vuex.Store({
  // default statte
  state: {
    user: {
      loggedIn: false,
      data: undefined,
      profile: {
        proMembership: { 
          status: "unknown",
          isActive: false
        }
      }
    }
  },
  getters: {
    user(state){
      return state.user
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_USER_PROFILE(state, value) {
      if (value != null) {
        state.user.profile = value;
      }
    }
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
          uid: user.uid
        });

      // after fetching the user, always fetch their profile
      this.dispatch("fetchUserProfile", user.uid)
      } else {
        commit("SET_USER", null);
      }
    },
    fetchUserProfile({commit}, userId) {
        return firebase.database().ref('/users/' + userId).once('value').then(function(snapshot) {
            commit("SET_USER_PROFILE", snapshot.val())
        });
        
    },
    createCheckoutPage({commit}, {user, trialDuration, planId}) {
      return new Promise((resolve, reject) => {
          axios.post(process.env.VUE_APP_CF_CREATE_CHECKOUT_URL, {
            "data": { 
              "userId": user.data.uid,
              "trialDuration": trialDuration,
              "priceId": planId
            }
          }).then((res) =>  {
            resolve(res)
          }).catch((error) => {
            reject(error)
          });
      })
    },
    createCustomerPortalPage({commit}, {customerId}) {
      return new Promise((resolve, reject) => {
          axios.post(process.env.VUE_APP_CF_CREATE_CUSTOMER_PAGE_URL, {
            "data": { 
              "customerId": customerId,
            }
          }).then((res) =>  {
            resolve(res)
          }).catch((error) => {
            reject(error)
          });
      })
    }

  }
});