<template>
  <div class="min-h-screen flex flex-col">
    <navbar v-if="!hideNavBar"></navbar>
    <router-view @hideNavBar="setHideNavBar"/>
  </div>
</template>

<script>
import navbar from "./components/Navbar";
export default {
  components: {
    navbar
  },
  data() {
    return {
      hideNavBar: false
    }
  },
  methods: {
    setHideNavBar(value) {
      this.hideNavBar = value
    }
  }
};
</script>

<style lang="css"> 
/* this is for gpu animations  https://stackoverflow.com/questions/18529381/what-does-webkit-transform-translate3d0-0-0-exactly-do-apply-to-body */
body {
  -webkit-transform: translate3d(0, 0, 0);
   -moz-transform: translate3d(0, 0, 0);
   -ms-transform: translate3d(0, 0, 0);
   transform: translate3d(0, 0, 0);
}

</style> 