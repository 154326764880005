<template>
	<div class="" >
		<h1 class="mt-6 block  leading-8 tracking-tight text-gray-900 sm:text-4xl px-8"> Start remote EMDR therapy today </h1> 
		<div class="prose prose-indigo prose-lg mt-4 text-gray-500 mx-auto px-8"> 
		<p> CloudEMDR is the easiest way to do EMDR therapy over a video call. Our basic version is free and you don't even need an account. It works with any video call tool. This video explains how it works: </p> 
		
		<iframe src="https://www.youtube-nocookie.com/embed/BEjEBNhzn3k" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" class="mt-4" allowfullscreen></iframe>
		
		<p class="mt-4"> Our Pro version is the best way to do EMDR therapy remotely. It doesn't require screen sharing, has better animations, higher bilateral sound quality and more options. This results in a more immersive experience for your client.</p>
		
		<!-- ONLY SHOW ON HIGH DISPLAYS -->
		<div class="shadow mt-6 rounded-t-lg" v-show="showQuote">
			<div class=" px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
				<div class="relative text-lg text-gray-700 font-medium ">
				  <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-200" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
					<path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
				  </svg>
				  <p class="relative">As an EMDR Europe Accredited Senior Trainer CloudEMDR is my recommended online EMDR therapy tool.  I use it every week with my clients and I love the many options is has that allow you to tax the working memory and keep the client grounded.  I also recommend it on every course to our delegates and we have had great feedback from them as well.</p>
				</div>
				</div>
	
				<cite class="relative flex bg-indigo-600 rounded-b-lg not-italic py-5 px-2">
					<span class="relative ml-4 text-indigo-300 font-semibold">
					  <p class="text-white font-semibold sm:inline">Matthew Wesson </p>
					  <p class="sm:inline">Director at EMDR ACADEMY</p>
					</span>
				  </cite>
			</div>
		</div>
		
		<div class="block items-center my-8">
			<center> 
			  <div class="block mb-3">
				<a href="#" class="cta-button" @click="goToAccount">
				  Sign up for CloudEMDR Pro
				</a>
			  </div>
			  or
				<a href="#" @click="handleUseBasic" class="text-indigo-500">
				  use the basic version for free
				</a>
			</center>
		</div>
		
	</div>
</template>

<script>
export default {
	name: 'Promo',
	data() { 
		return {
			showQuote: false // for now we don't show any quotes. 	
		}
	},
	methods: { 
		handleUseBasic() {
			sa_event("promo_use_basic_tapped");
			this.hidePromo()
		},
		handleClose() {
			sa_event("promo_close_tapped");
			this.hidePromo()
		},
		goToAccount() {
			sa_event("promo_sign_up_tapped");
			this.$router.push({name: "register"});
		},
		hidePromo() {
			 sa_event("promo_closed");
			 this.$emit("hidePromo");
		 },
	}
}
</script>
<style> 
iframe {
	margin-bottom: 10px;
	height: 338px;
	width: 100%;
}

@media only screen and (max-width: 880px) {
	iframe {
		margin-bottom: 10px;
		height: 200px;
		width: 100%;
	}
}


</style> 