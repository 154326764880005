<template>
    <div class="remote">
        <form>
            <div v-if="showUploadBackground" class="px-5 py-4"> 
              <span class="text-2xl font-medium text-gray-900"> Upload Your Own Background </span>
              <UploadBackground class="mt-4" v-bind:backgroundData="uploadBackgroundData" @changed="handleBackgroundUpload"> </UploadBackground>
              <button class="rounded-md px-5 bg-red-300 py-2 mt-4" @click="showUploadBackground = false; playerSettings.background = `light`"> Cancel </button><button @click="showUploadBackground = false" class="rounded-md mx-5 px-5 bg-blue-500 text-white font-medium py-2"> Use this image </button>
            </div>
            <div class="px-5 py-4" v-if="showControls && !showUploadBackground">

                <h2 class=""> Controls </h2>
                
                  <p class="text-gray-600"> <span v-if="playerSettings.mode=='dot' && !playerSettings.isRemote" > The controls will disappear when you press Start. If you click the ⚙ below it will appear again. </span> <span v-if="playerSettings.mode=='stroop'"> The stroop test is another great way to do EMDR remotely. It also works great when your client is on a phone. If you click the ⚙ below you can hide or show these settings. </span> <a href="https://docs.cloudemdr.com/guides/video-call.html" class="text-blue-500"> How to use this in a Video Call?</a> 
                  </p>
                  
                  <div class="segmented-control block mt-3">
                      <input class="segmented-control__input" id="radio_option1" type="radio" name="segment" v-model="playerSettings.mode" value="dot" @change="modeChanged"/>
                      <label class="segmented-control__label segmented-control__label--start half" for="radio_option1">EMDR Dot Mode</label>
                      <input class="segmented-control__input" id="radio_option2" type="radio" name="segment" v-model="playerSettings.mode" value="stroop" @change="modeChanged" />
                      <label class="segmented-control__label segmented-control__label--end half" for="radio_option2">Stroop Test</label>
                  </div>

                <div v-show="playerSettings.mode=='stroop'"> 
                    <label for="stroop-language" class="block-label">Language</label>
                    <select class="form-control rounded-md mt-1" id="stroop-language" v-model="language" @change="refreshStroop">
                        <option value="en"> English</option>
                        <option value="nl">Nederlands</option>
                        <option value="no">Norsk</option>
                    </select>

                </div>
  
                <div v-show="playerSettings.mode=='dot'" class="dot-settings mt-4"> 

                    <label for="speed" class="block-label mt-3">Speed</label>
                    <input type="range"
                        class="form-control"
                        min="1"
                        max="65"
                        v-model="playerSettings.speed">

                    <label for="size" class="block-label mt-3">Size</label>
                    <input type="range"
                        class="form-control "
                        min="40"
                        max="200"
                        v-model="playerSettings.size">
                    
                    
                    <label for="background" class="block-label"> Background </label>  
                      <select class="form-control border-gray-300 rounded-md mt-1" id="background" v-model="playerSettings.background" @change="toggleBackgroundColor">
                        <option value="light">Light Background</option>
                        <option value="dark">Dark Background</option>
                        <option value="practice"> Therapy Practice </option>
                        <option value="forest"> Forest </option>
                        <option value="mountains"> Mountains </option>
                        <option value="beach"> Beach </option> 
                        <option value="hospital"> Hospital </option>
                        <option value="upload" v-bind:disabled="!this.user.profile.proMembership.isActive"> <b> Upload Image (pro users only) </b> </option>
                    </select>


                    
                    <label for="movement" class="block-label"> Dot Movement </label>
                    <select class="form-control  border-gray-300 rounded-md mt-1" id="movement" v-model="playerSettings.movement" @change="handleMovementChange($event.target.value)">
                        <option value="emdr-linear">Linear Dot Movement</option>
                        <option value="emdr-non-linear"> 8-shape Dot Movement </option>
                        <option value="random"> Random </option>
                        <option value="diamond">  PROOO Diamond (pro users only) </option>
                        <option value="diagonal"> Diagonal (pro users only) </option>
                        <option value="diagonal-flipped"> Diagonal Flipped (pro users only) </option>
                    </select>
                     
                    <!-- add update colorChange -->
                     <label for="dotStyle" class="block-label"> Dot Style </label>  
                       <select class="form-control border-gray-300 rounded-md mt-1" id="dotStyle" v-model="playerSettings.dotStyle" @change="handleDotStyleChange($event.target.value)">
                         <option value="noStyle"> Grey Dot</option>
                         <option value="color"> Color Changing Dot </option>
                         <option value="emoji"> Emoji Changing Dot (pro users only)</option>
                         <option value="animal"> <b> Animal Dot (pro users only) </b> </option>
                     </select>


                    <label for="Sound" class="block-label"> Sound </label>
                    <select class="form-control  border-gray-300 rounded-md mt-1" id="sound" v-model="playerSettings.sound" @change="handleSoundChange($event.target.value)">
                      <option value="none">No Sound</option>
                      <option value="01-EMDR"> Sound 1</option>
                      <option value="PRO-05-EMDR"> Sound 2 (pro users only)</option>
                      <option value="PRO-12-EMDR"> Sound 3 (pro users only)</option>
                      <option value="PRO-14-EMDR"> Sound 4 (pro users only)</option>
                      <option value="PRO-18-EMDR"> Sound 5 (pro users only)</option>
                      </select>
                      <div v-if="playerSettings.sound != 'none' && playerSettings.isRemote" @click="muted = !muted"> <i class="fa fa-volume-off"></i> Mute Sound (client will still hear it) <input class="form-check-input" type="checkbox" id="muteCheckBox" v-model="muted"> </div>
                      
            
                </div>
            </div>
            <div class="px-8 space-x-4 mt-6">
              
              <!-- Stroop next button -->                
              <button v-if="playerSettings.mode=='stroop'"  :disabled="!enableStart" class="cta-button" :class="{'opacity-40': !enableStart}"  @click="refreshStroop" type="button">Next 16 words</button>
                
              <!-- Start Button -->                
              <button id="start" :disabled="!enableStart" type="button" class="cta-button" :class="{'opacity-40': !enableStart}" @click="togglePlay" v-if="!playerSettings.isPlaying && playerSettings.mode == 'dot'">Start</button>
              
              <!-- Stop Button -->                
              <button id="stop" type="button" class="cta-button bg-gray-600" @click="togglePlay" v-if="playerSettings.isPlaying && playerSettings.mode == 'dot'">Stop</button>
              
              <!-- Remote Button -->                
              <router-link to="host" v-if="showControls && !playerSettings.isRemote && !playerSettings.isPlaying"><button id="start" type="button" class="cta-2-button">Start a Remote Session</button></router-link>

              <!-- Timer (only shown on remote sessions) -->                
              <span id="timer" v-if="playerSettings.mode=='dot'&&playerSettings.isPlaying&&playerSettings.isRemote&& timeRunning>0"> {{ timeRunning | moment("mm:ss") }} </span>
              
              <!-- COG button -->                
              <button type="button" class="button-transparent" @click="toggleControls" v-show="!playerSettings.isRemote">
                  <i class="fa fa-cog"></i>
                  
              </button>
            </div>
        </form>
        <div v-if="showProModal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div class="sm:flex sm:items-start">
                            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                <!-- Heroicon name: outline/sparkles -->
                                <svg class="h-6 w-6 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                                </svg>
                            </div>
                            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                    Unlock CloudEMDR Pro Features
                                </h3>
                                <div class="mt-2">
                                    <p class="text-sm text-gray-500">
                                        To use this feature, you need to have a CloudEMDR Pro account. Upgrade to CloudEMDR Pro to access premium features and enhance your EMDR therapy sessions. 
                                    </p>
                                    <ul class="mt-3 list-disc list-inside text-sm text-gray-500">
                                        <li>Remote sessions </li>
                                        <li>Better animation and sound quality </li>
                                        <li>More movement patterns (Diamond, Diagonal)</li>
                                        <li>Emoji and animal dot styles</li>
                                        <li>High-quality bilateral sounds</li>
                                        <li>Customizable backgrounds</li>
                                        <li>Priority support</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button type="button" @click="upgradeToPro" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                            Upgrade to Pro
                        </button>
                        <button type="button" @click="continueWithBasic" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                            Continue with Basic
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script scoped>
import {mixin as VueTimers} from 'vue-timers'
import { mapGetters } from "vuex";
require('howler');
import UploadBackground from '@/components/player/UploadBackground.vue';

export default {
    name: 'controls', 
    props: {
        playerSettings: Object,
        enableStart: {
            type: Boolean,
            default: true
        },
        showControls: {
          type: Boolean, 
          default: true
        }
    },
    components: {
      UploadBackground
    },
    data() {
        return {
            uploadBackgroundData: {
              dotColor: "#333",
              imageURL: ""
            },
            showUploadBackground: false, 
            muted: false,
            timeRunning: 0,
            defaultDotColor: {
                light: "#333333",
                dark: "#CCCCCC",
                upload: "#CCCCCC"
            },
            colors: ["red", "orange", "purple", "yellow", "green", "blue"],
            animals: ["🦊", "🦒", "🦜", "🦋", "🐸", "🐙", "🦁", "🐯", "🐷", "🐵", "🐬", "🐢", "🦀"],
            emojis: ["🍎", "🚀", "🌈", "🌞", "🍕", "🌺", "⚽️", "🎸", "🌙"],
            modes: [
                { label: 'EMDR with Dot', value: 'dot' },
                { label: 'Stroop Test', value: 'stroop' },
            ],
            language: 'en',
            showProModal: false,
            currentProFeature: null
        }
    },
    methods: {
        modeChanged() {
            this.playerSettings.isPlaying = false; 
            this.updateDotStyle() 
            this.refreshStroop();
            sa_event(`mode_set_${this.playerSettings.mode}`);
        },
        continueWithBasic() {
            if (this.currentProFeature) {
                switch (this.currentProFeature.name) {
                    case 'movement':
                        this.playerSettings.movement = 'emdr-linear'; // Set to a default non-pro value
                        break;
                    case 'dotStyle':
                        this.playerSettings.dotStyle = 'noStyle'; // Set to a default non-pro value
                        break;
                    case 'sound':
                        this.playerSettings.sound = 'none'; // Set to a default non-pro value
                        break;
                    // Add other cases as needed
                }
            }
            this.showProModal = false;
            this.currentProFeature = null;
        },
        upgradeToPro() {
            this.showProModal = false;
            this.currentProFeature = null;
            // Check if the user is authenticated
            if (this.user.loggedIn) {
                this.$router.push({ name: 'account' });
            } else {
                // User is not logged in, send them to the registration page
                this.$router.push({ name: 'register' });
            }
            sa_event("upgrade_to_pro_clicked");
        },
        changeAudio() {
            sa_event("change_audio");
        },
        handleBackgroundUpload() {
			sa_event("background uploaded");
            this.playerSettings.color = this.uploadBackgroundData.dotColor;
            this.playerSettings.background = this.uploadBackgroundData.imageURL;
        },
        togglePlay() {
            // If we start playing in a non remote env, hide the settings.
            if (!this.playerSettings.isRemote && !this.playerSettings.isPlaying) {
              this.$emit("updateShowControls", false);
            }

            this.playerSettings.isPlaying = !this.playerSettings.isPlaying;
            
            if (this.playerSettings.isPlaying) {
                sa_event("start_session");
            } else {
                sa_event("pause_session");
            }

            this.updateDotStyle();
         },
        toggleControls() {
            if(!this.playerSettings.isRemote){ 
                this.$emit('updateShowControls', !this.showControls);
            }
        },
        toggleBackgroundColor() {
            if(this.playerSettings.background == "upload") {
              this.showUploadBackground = true
            } else {
              this.showUploadBackground = false
              this.playerSettings.color = this.dotColor 
            }
            sa_event("background changed");
        },
        updateDotStyle() {
            // start the change timer if necessary.           
            if(this.playerSettings.dotStyle != "noStyle" && this.playerSettings.isPlaying){
                this.startChangeTimer()
            } else {
                this.stopChangeTimer()
            }

            // Set dot color to light grey for emoji or animal mode
            if (this.playerSettings.dotStyle === "emoji" || this.playerSettings.dotStyle === "animal") {
                this.playerSettings.color = "#CCCCCC"
            } else {
                this.playerSettings.color = this.dotColor
            }

            // Track dot style change event
            sa_event(`dot_style_changed_${this.playerSettings.dotStyle}`);
        },
        startChangeTimer() {
          // we have different interval timers for different dotStyles 
          // but they are all calling the updateDotStyleTimer() function when a change is triggered
          this.$timer.stop('shortChangeTimer');
          this.$timer.stop('longChangeTimer');

          switch(this.playerSettings.dotStyle) {
            case "color":
              this.$timer.start('shortChangeTimer');
            break;
            case "animal":
            case "emoji":  
              this.$timer.start('longChangeTimer');  
            break; 
            default: 
            break;
          }
          
        },
        stopChangeTimer() {
            this.$timer.stop('shortChangeTimer');
            this.$timer.stop('longChangeTimer');
            this.playerSettings.color = this.dotColor
            this.playerSettings.dotContent = ""
        },
        updateDotStyleTimer(){ 
            
            switch (this.playerSettings.dotStyle) {
                case "color": 
                  var chosenNumber = Math.floor(Math.random() * this.colors.length);
                  this.playerSettings.color = this.colors[chosenNumber];
                  break
                case "animal": 
                  var chosenNumber = Math.floor(Math.random() * this.animals.length); 
                  this.playerSettings.dotContent = this.animals[chosenNumber]; 
                  break
                case "emoji":  
                  var chosenNumber = Math.floor(Math.random() * this.emojis.length); 
                  this.playerSettings.dotContent = this.emojis[chosenNumber]; 
                  break;
                default: 
                  break;
            }
            this.$emit('changed');
        },
        updateTimeRunning() {
            this.timeRunning++
        },
        refreshStroop() { 
            var stroopColors = ["red", "yellow", "blue", "green"] 
            var stroopColorNames = ["red", "yellow", "blue", "green"]
            if (this.language == "nl") {
                stroopColorNames = ["rood", "geel", "blauw", "groen"] 
            } 
            
            if (this.language == "no") {
                stroopColorNames = ["rød", "gul", "blå", "grønn"] 
            }

            var array = []
            for (var i = 1; i <= 16; i++) {
                var colorNumber = Math.floor(Math.random() * stroopColors.length);
                var titleNumber = Math.floor(Math.random() * stroopColors.length);
                array.push({
                    title: stroopColorNames[colorNumber],
                    color: stroopColors[titleNumber]
                });
            }
            this.playerSettings.stroopWords = array; 
        },
        handleProFeature(feature, value) {
            if (!this.user.profile.proMembership.isActive) {
                this.showProModal = true;
                this.currentProFeature = { name: feature, value: value };
                return false; // Indicate that the change should not proceed
            }
            return true; // Indicate that the change can proceed
        },

        handleMovementChange(value) {
            const proMovements = ['diamond', 'diagonal', 'diagonal-flipped'];
            if (proMovements.includes(value)) {
                if (!this.handleProFeature('movement', value)) {
                    // If handleProFeature returns false, revert to the previous value
                    this.$nextTick(() => {
                        this.playerSettings.movement = this.playerSettings.movement;
                    });
                }
            }
        },

        handleDotStyleChange(value) {
            const proDotStyles = ['emoji', 'animal'];
            if (proDotStyles.includes(value)) {
                if (!this.handleProFeature('dotStyle', value)) {
                    // If handleProFeature returns false, revert to the previous value
                    this.$nextTick(() => {
                        this.playerSettings.dotStyle = this.playerSettings.dotStyle;
                    });
                }
            } else {
                this.updateDotStyle();
            }
        },

        handleSoundChange(value) {
            const proSounds = ['PRO-05-EMDR', 'PRO-12-EMDR', 'PRO-14-EMDR', 'PRO-18-EMDR'];
            if (proSounds.includes(value)) {
                if (!this.handleProFeature('sound', value)) {
                    // If handleProFeature returns false, revert to the previous value
                    this.$nextTick(() => {
                        this.playerSettings.sound = this.playerSettings.sound;
                    });
                }
            } else {
                this.changeAudio();
            }
        },    },
    mixins: [VueTimers],
    timers: {
        shortChangeTimer: {
            time: 700,
            immediate: true,
            repeat: true,
            callback: function() {
              this.updateDotStyleTimer()
            },
        },
        longChangeTimer: {
            time: 2000,
            immediate: true,
            repeat: true,
            callback: function() {
              this.updateDotStyleTimer()
            },
        },
        updateTimeRunning: {
            time: 1000,
            immediate: false,
            repeat: true
        }
    },
    computed: {
        ...mapGetters({
            user: "user"
        }),
        dotColor: function() {
			// automatic dotColors are handles inside the uploader
            switch(this.playerSettings.background) {
              case "light":
              case "hospital":
                return this.defaultDotColor.light;
                break;  
              case "forest": 
              case "practice": 
              case "dark": 
              case "mountain":
              case "beach": 
                return this.defaultDotColor.dark;
                break; 
             default: 
                return this.defaultDotColor.upload; 
                break; 
            }
        }
    },
    watch: {
        playerSettings: {
            handler: function(newValue, oldValue) {
                this.$emit('changed');
            },
            deep: true
        },
        muted: {
            handler: function(newValue, oldValue) {
                Howler.mute(newValue)
            }
        },
        'playerSettings.isPlaying': function(newValue, oldValue) {
                if(newValue == true && oldValue == false) {
                    this.timeRunning = 0;
                    this.$timer.start('updateTimeRunning');
                } else if(newValue == false && oldValue == true) {
                    this.$timer.stop('updateTimeRunning');
                    this.timeRunning = 0;
                }
        }
    }
}
</script>


<style> 
  @import './../../styles/SegmentControl.css';

  .block-label { 
    @apply text-gray-700;
    @apply block; 
    @apply font-medium;
    @apply mt-4;
  }
  
  .form-control {
    @apply w-full;
    
  }
  
</style> 
  
