<template>
    <div class="min-h-full bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 flex-grow">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Create your account
        </h2>
      </div>
      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Warning v-show="this.error" v-bind:isError=true :content="this.error" class="mb-4"> </Warning>
          <form class="space-y-6" @submit.prevent="submit">
              
            <div>
              <label for="name" class="block text-sm font-medium text-gray-700">
                Name
              </label>
              <div class="mt-1">
                <input id="name" name="name" type="name" autocomplete="name" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" autofocus v-model="form.name">
              </div>
            </div>


            <div> 
              <label for="email" class="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div class="mt-1">
                <input id="email" name="email" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" v-model="form.email">
              </div>
            </div>
            
            <div>
              <label for="password" class="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div class="mt-1">
                <input id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" v-model="form.password">
              </div>
            </div>
  
    
            <div>
              <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Sign Up
              </button>
            </div>
          </form>
          <p class="text-gray-400 mt-4"> If you trouble signing up, reach out to <a href="MAILTO:support@cloudemdr.com" class="text-blue-600">support</a>.</p>
          <p class="text-gray-400"> Already have an account? <router-link to="login" class="text-blue-600"> Sign-in </router-link> </p>

        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Warning from '@/components/Warning.vue'

export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        password: ""
      },
      error: undefined
    };
  },
  components: {
    Warning
  },
  computed: { 
    ...mapGetters({
      user: "user"
    })
  },
  methods: {
    async submit() {
      try {
        const user = await this.$auth.createAccount(this.form.name, this.form.email, this.form.password)
      } catch(error) {
          this.error = error.message;
      }
    },
    async nextPage() { 
      const destination = {name: 'account'}
      if(this.$route.name != destination.name) {
        try {
          await this.$router.replace(destination)
        } catch(err) {
          throw new Error(`Error routing after sign up up: ${err} with route ${destination}`);
        }
      }
    }
  }, 
  watch: {
    'user.data.displayName': function(newValue, oldValue) {
      // once the name of the user has been set go to the next page
      if(this.user.loggedIn && newValue != null) {
        this.nextPage() 
      }
    }
  } 
};
</script>

