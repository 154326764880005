<template>
   <div>
     <!-- This example requires Tailwind CSS v2.0+ -->
     <div class="bg-white">
       <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
         <div class="sm:flex sm:flex-col sm:align-center">
           <h1 class="text-5xl font-extrabold text-gray-900 sm:text-center">Get the most out of CloudEMDR</h1>
           
           <p class="mt-5 text-xl text-gray-500 sm:text-center"> 
             <template v-if="!user.profile.proMembership.isActive"> 
             Sign up to CloudEMDR Pro today to get the most out of CloudEMDR. Currently, can only use limited functionality of the app and you're not supporting the development. 
             </template>
             <template v-else> 
             Thanks for supporting CloudEMDR.
             </template>
           </p>
           
           <!-- Show plan duration picker if yearly plans are available -->
           <div class="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
        
             <button type="button" @click="isMonthly = true" v-bind:class="{'bg-white': isMonthly}" class="relative w-1/2  border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8">Monthly</button>
             <button type="button" @click="isMonthly = false" v-bind:class="{'bg-white': !isMonthly}" class=" relative w-1/2 ml-0.5 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8">Yearly
                 <h3 class="inline-flex px-2 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600" id="tier-standard">
               Save {{difference}}%
             </h3>
</button>
           </div>
           
         </div>
         <div class="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-2">
           <div class="yolo py-10 px-5"> 
             <div class="border border-gray-200 min-h-full rounded-lg shadow-sm divide-y divide-gray-200">
               <div class="p-6">
                 <h2 class="text-lg leading-6 font-medium text-gray-900">Free</h2>
                 <p class="mt-4 text-sm text-gray-500">To keep remote EMDR therapy accessible, the basic functionality is free.</p>
                 <template v-if="user.profile.proMembership.isActive"> 
                   <a href="#" class="mt-8 block w-full border border-gray-500 rounded-md py-2 text-sm font-semibold text-gray-700 bg-white text-center focus:outline-none focus:ring-2 focus:ring-offset-2 noselect" >The Default Plan</a>
                 </template>
                 <template v-else> 
                   <a href="#" class="mt-8 block w-full border border-gray-500 rounded-md py-2 text-sm font-semibold text-gray-700 bg-white text-center focus:outline-none focus:ring-2 focus:ring-offset-2 noselect" >Your Current Plan</a>
                 </template>
               </div>
               <div class="pt-6 pb-8 px-6">
                 <h3 class="text-xs font-medium text-gray-900 tracking-wide uppercase">What's included</h3>
                 <ul class="mt-6 space-y-4">
                   <PlanListItem>1 Sound</PlanListItem>
                   <PlanListItem>2 Animation Patterns</PlanListItem>
                   <li class="flex space-x-3">
                      <!-- Heroicon name: check -->
                      <svg class="flex-shrink-0 h-5 w-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg>
                      <span class="text-sm text-gray-500">Poor animation and sound quality due to the lack of remote sessions</span>
                    </li>
  
                 </ul>
               </div>
             </div>
           </div>
      
           <div class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 shadow-lg">
             <div class="p-6">
               <h2 class="text-lg leading-6 font-medium text-gray-90 inline-flex">Pro</h2>
               <span class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600 float-right" id="tier-standard">
                 Most popular
               </span>
 
               <p class="mt-4 text-sm text-gray-500">The best option for therapists that conduct EMDR therapy remotely. All free functionality, and so much more.</p>
               <template v-if="user.profile.proMembership.isActive"> 
                 <p class="mt-4">
                    <span class="text-3xl mt-2 inline-block font-extrabold text-gray-900">Just {{ monthlyChargePrice }}/mo </span>
                    <span class="text-base font-medium mt-1 text-gray-500 block">  Cancel Anytime</span>
   
                    <span class="text-sm text-gray-400 block"> Including VAT </span>
                  </p>
   
                 <a href="#" class="mt-8 block w-full border border-gray-500 rounded-md py-2 text-sm font-semibold text-gray-700 bg-white text-center focus:outline-none focus:ring-2 focus:ring-offset-2 noselect" >Your Current Plan</a>
               </template>
               <template v-else> 
                 <p class="mt-4">
                    <span class="text-3xl mt-2 inline-block font-extrabold text-gray-900">First 3 weeks are <u>free</u> </span>
                    <span class="text-base font-medium mt-1 text-gray-500 block"> after that {{ plan.monthlyPrice }}/mo 
                      <template v-if="!isMonthly"> billed annually ({{plan.localePrice}}) </template>
                      - cancel anytime</span>
   
                    <span class="text-sm text-gray-400 block"> Including VAT </span> 
                  </p>
   
                 <button class="mt-4 block w-full  rounded-md py-4 text-sm font-semibold text-white text-center text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="selectPlan">Start Your Free Pro Trial</button>
                 <span v-if="loading" class="text-base mt-4 block font-medium text-black"> Please wait, loading payment processor... </span> 
                 <span class="text-sm text-gray-500 block mt-4"> Need 3+ licenses for your team? contact us at <a href="mailto:support@cloudemdr.com" class="hover:underline hover:text-indigo-600">support@cloudemdr.com</a> </span>

               </template>
             </div>
             <div class="pt-6 pb-8 px-6">
               <h3 class="text-xs font-medium text-gray-900 tracking-wide uppercase">What's included</h3>
               <ul class="mt-6 space-y-4">
                 <!-- TODO: add link to remote page --> 
                 <PlanListItem>Remote Sessions (your clients won't see your controls, learn more).</PlanListItem>
                 <PlanListItem>100% smooth movements for your clients </PlanListItem>
                 <PlanListItem>5 sounds</PlanListItem>
                 <PlanListItem>7+ Animation Patterns </PlanListItem>
                 <PlanListItem>Stroop Test</PlanListItem>
                 <PlanListItem>Custom Backgrounds</PlanListItem>
                 <PlanListItem>24/7 Premium Support</PlanListItem>
                 <PlanListItem>More to come!</PlanListItem>

               </ul>
             </div>
           </div>
         </div>
       </div>
     </div>
  </div>
</template>

<script>
import PlanListItem from "@/components/PlanListItem.vue"
import getPlan from "./../plan.js";

export default {
    name: "Plans",
    props: {
        user: Object,
        loading: Boolean,
        didSelectPlan: Function, 
    },
    components: {
      PlanListItem
    },
    data() {
      return {
        isMonthly: true, 
        monthlyPlan: getPlan(this.$shared.locale, "monthly"),
        yearlyPlan: getPlan(this.$shared.locale, "yearly"),
      }
    },
    methods: {
      selectPlan() {
        this.didSelectPlan(this.plan)
      },
      round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
        }
    
    },
    computed: {
      plan: function() {
        if(this.isMonthly) { 
          return this.monthlyPlan
        } else {
          return this.yearlyPlan
        }
      },
      difference: function() {
        return this.round((1-(this.yearlyPlan.amount/12)/(this.monthlyPlan.amount))*100, 0)
      },
      monthlyChargePrice: function () {
        if (this.user.profile.proMembership.stripeSubscription) {
          var monthlyPrice = new Intl.NumberFormat(this.$shared.locale, {
            style: "currency",
            currency: this.user.profile.proMembership.stripeSubscription.plan
              .currency,
          }).format(
            this.user.profile.proMembership.stripeSubscription.plan.amount / 100
          );
          return monthlyPrice;
        } else {
          return "Unknown - contact support if there's an issue";
        }
      }
    }
}
</script>

<style>
.noselect {
  cursor: auto;
  opacity: 0.5;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

</style>
