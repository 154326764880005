<template>
  <div class="min-h-screen flex overflow-hidden bg-white">
    <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
    <div class="md:hidden" v-show="showSidebar">
      <div class="fixed inset-0 flex z-40">
        <transition
          enter-active-class="transition-opacity ease-linear duration-8000"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition-opacity ease-linear duration-8000"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div class="fixed inset-0">
            <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
          </div>
        </transition>

        <transition
          enter-active-class="transition ease-in-out duration-8000 transform"
          enter-from-class="-translate-x-full"
          enter-to-class="translate-x-0"
          leave-active-class="transition ease-in-out duration-8000 transform"
          leave-from-class="translate-x-0"
          leave-to-class="-translate-x-full"
        >
          <div class="relative flex-1 flex flex-col max-w-xs w-full bg-white">
            <div class="absolute top-0 right-0 -mr-12 pt-2">
              <button
                class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                @click="showSidebar = false"
              >
                <span class="sr-only">Close sidebar</span>
                <!-- Heroicon name: x -->
                <svg
                  class="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
                <a
                  href="#plans"
                  @click="(showPlans = true), (showSidebar = false)"
                  class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                >
                  <!-- Current: "text-gray-500", Default: "text-gray-400 group-hover:text-gray-500" -->
                  <!-- Heroicon name: home -->

                  <svg
                    class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                    ></path>
                  </svg>

                  Plans & Upgrade
                </a>

                <a
                  href="#account"
                  @click="showSidebar = false"
                  class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                >
                  <!-- Heroicon name: inbox -->
                  <svg
                    class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>

                  Account Settings
                </a>

                <a
                  href="#billing"
                  @click="showSidebar = false"
                  v-if="user.profile.proMembership.isActive"
                  class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                >
                  <!-- Heroicon name: chart-bar -->
                  <svg
                    class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                    ></path>
                  </svg>
                  Billing
                </a>
              </nav>
            </div>
          </div>
        </transition>
        <div class="flex-shrink-0 w-14">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </div>
    </div>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex flex-col h-0 flex-1 border-r border-gray-200 bg-white">
          <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <nav class="flex-1 px-2 bg-white space-y-1">
              <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
              <a
                href="#plans"
                @click="showPlans = true"
                class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
              >
                <!-- Current: "text-gray-500", Default: "text-gray-400 group-hover:text-gray-500" -->
                <!-- Heroicon name: home -->

                <svg
                  class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                  ></path>
                </svg>

                Plans & Upgrade
              </a>

              <a
                href="#account"
                class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
              >
                <!-- Heroicon name: inbox -->
                <svg
                  class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>

                Account Settings
              </a>

              <a
                href="#billing"
                v-if="user.profile.proMembership.isActive"
                class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
              >
                <!-- Heroicon name: chart-bar -->
                <svg
                  class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                  ></path>
                </svg>
                Billing
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
        <button
          class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          @click="showSidebar = true"
        >
          <span class="sr-only">Open sidebar</span>
          <!-- Heroicon name: menu -->
          <svg
            class="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>
      <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <div class="md:py-6">
          <div class="max-w-full">
            <div class="flex-col place-self-center">
              <Warning
                v-if="error"
                :isError="true"
                :content="error"
                class="mt-4"
              >
              </Warning>
              <Plans
                id="plans"
                v-if="!user.profile.proMembership.isActive || showPlans"
                v-bind:user="user"
                :didSelectPlan="didSelectPlan"
                :loading="loading"
              ></Plans>
              <div class="" id="account">
                <div
                  class="bg-white py-6 px-4 sm:p-6 w-full border-t border-gray-300"
                >
                  <div>
                    <h2
                      id="payment_details_heading"
                      class="text-lg leading-6 font-medium text-gray-900"
                    >
                      Account Information
                    </h2>
                    <p class="mt-1 text-sm text-gray-500">
                      All relevant information for your account.
                    </p>
                  </div>
                  <Warning
                    class="mt-4"
                    v-if="
                      user.loggedIn &&
                      user.profile.proMembership.status == 'pastDue'
                    "
                  >
                    <p>
                      Something went wrong with your last payment, please
                      contact support to re-enable your subscription.
                    </p>
                    <button
                      @click="contactSupport"
                      type="button"
                      class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
                    >
                      Contact Support
                    </button>
                  </Warning>

                  <div class="mt-6 grid grid-cols-4 gap-6">
                    <div class="col-span-4 sm:col-span-2">
                      <label
                        for="name"
                        class="block text-sm font-medium text-gray-700"
                        >Name</label
                      >
                      {{ user.data.displayName }}
                    </div>

                    <div class="col-span-4 sm:col-span-2">
                      <label
                        for="email"
                        class="block text-sm font-medium text-gray-700"
                        >Email</label
                      >
                      {{ user.data.email }}
                    </div>

                    <div class="col-span-4 sm:col-span-1">
                      <label
                        for="sub_status"
                        class="block text-sm font-medium text-gray-700"
                        >Subscription</label
                      >
                      {{ user.profile.proMembership.status }}
                    </div>
                  </div>
                </div>
                <div class="px-4 text-left sm:px-6">
                  <button
                    v-if="user.loggedIn"
                    @click="signOut"
                    type="button"
                    class="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  >
                    Sign out
                  </button>
                </div>
              </div>
              <div
                v-if="user.profile.proMembership.isActive"
                class="mt-6 w-full border-t border-gray-300"
                id="billing"
              >
                <div class="bg-white py-6 px-4 sm:p-6">
                  <div>
                    <h2
                      id="payment_details_heading"
                      class="text-lg leading-6 font-medium text-gray-900"
                    >
                      Billing Information
                    </h2>
                    <p class="mt-1 text-sm text-gray-500">
                      Everything related to billing.
                    </p>
                  </div>
                  <div class="mt-6 grid grid-cols-4 gap-6">
                    <template
                      v-if="user.profile.proMembership.stripeSubscription"
                    >
                      <div class="col-span-4 sm:col-span-2">
                        <label
                          for="name"
                          class="block text-sm font-medium text-gray-700"
                          >Fee</label
                        >
                        {{ chargePrice }} per {{chargePeriod}}
                      </div>

                      <div class="col-span-4 sm:col-span-2">
                        <label
                          for="name"
                          class="block text-sm font-medium text-gray-700"
                          >Next Billing Date</label
                        >
                        {{
                          user.profile.proMembership.stripeSubscription
                            .current_period_end | moment("dddd, MMMM Do YYYY")
                        }}
                      </div>
                    </template>

                    <div class="col-span-4 sm:col-span-2">
                      <label
                        for="sub_status"
                        class="block text-sm font-medium text-gray-700"
                        >Subscription Type</label
                      >
                      {{ user.profile.proMembership.status }}
                    </div>

                    <div class="col-span-4 sm:col-span-2">
                      <label
                        for="sub_status"
                        class="block text-sm font-medium text-gray-700"
                        >Invoice e-mail address</label
                      >
                      {{ user.data.email }}
                    </div>
                  </div>
                </div>
                <div class="px-4 text-left sm:px-6 space-x-5">
                  <button
                    v-if="
                      user.loggedIn &&
                      user.profile.proMembership.stripeSubscription != undefined && 
                      user.profile.proMembership.stripeSubscription.customer != undefined
                    "
                    @click="updateBilling"
                    type="button"
                    class="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  >
                    Manage Billing
                  </button>

                  <button
                    v-if="user.loggedIn"
                    @click="contactSupport"
                    type="button"
                    class="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  >
                    Contact Support
                  </button>

                  <button
                    v-if="user.loggedIn"
                    @click="cancelSubscription"
                    type="button"
                    class="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  >
                    Cancel Subscription
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Plans from "./Plans.vue";
import { loadStripe } from "@stripe/stripe-js";
import Warning from "@/components/Warning.vue";

export default {
  data() {
    return {
      error: null,
      loading: false,
      stripe: null,
      showPlans: false,
      showSidebar: false,
    };
  },
  components: {
    Plans,
    Warning,
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
    // because people have different plans, we display the price that we find in their stripe subscription value
    chargePrice: function () {
      if (this.user.profile.proMembership.stripeSubscription) {
        var monthlyPrice = new Intl.NumberFormat(this.$shared.locale, {
          style: "currency",
          currency: this.user.profile.proMembership.stripeSubscription.plan
            .currency,
        }).format(
          this.user.profile.proMembership.stripeSubscription.plan.amount / 100
        );
        return monthlyPrice;
      } else {
        return "Unknown - contact support if there's an issue";
      }
    },
    chargePeriod: function() {
      if (this.user.profile.proMembership.stripeSubscription) {
        return this.user.profile.proMembership.stripeSubscription.plan
      .interval; 
      } else {
        return "N/A";
      }
    }
  },
  async mounted() {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_API_KEY);
  },
  methods: {
    async signOut() {
      try {
        await this.$auth.logout();
        this.$router.replace({ name: "home" });
      } catch (error) {
        this.error = error;
      }
    },
    didSelectPlan(plan) {
      this.plan = plan;
      this.loading = true;

      this.$store
        .dispatch("createCheckoutPage", {
          user: this.user,
          trialDuration: 21,
          planId: this.plan.planId,
        })
        .then((res) => {
          this.stripe
            .redirectToCheckout({
              sessionId: res.data.sessionId,
            })
            .then(() => {
              this.loading = false;
            })
            .catch((error) => {
              this.error = `${error}, please try again or contact support at ${this.$shared.email}`;
              this.loading = false;
            });
        })
        .catch((error) => {
          this.error = `${error}, please try again or contact support at ${this.$shared.email}`;
          this.loading = false;
        });
    },
    updateBilling() {
      this.$store
        .dispatch("createCustomerPortalPage", {
          customerId: this.user.profile.proMembership.stripeSubscription
            .customer,
        })
        .then((res) => {
          console.log(res);
          window.location.href = res.data.url;
        })
        .catch((error) => {
          this.error = `${error}, please try again or contact support at ${this.$shared.email}`;
        });
    },
    cancelSubscription() {
      sa_event("click_cancel_subscription");
      alert(
        `To cancel your Cloud EMDR Pro subscription, please send a message to ${this.$shared.email}`
      );
    },
    contactSupport() {
      sa_event("click_contact_support");
      alert(`Please send an email to ${this.$shared.email}`);
    },
  },
};
</script>
