<template>
	<div>
	  <vue-dropzone re="imgDropZone" id="bg-dropzone" :include-styling="true" :options="dropzoneOptions" @vdropzone-file-added="uploadStared"> 
	  </vue-dropzone>
	  </div>
</template>

<script>
import firebase from "firebase";
import vueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
let uuid = require("uuid");

	export default {
		name: 'UploadBackground', 
		components: {
			vueDropzone
		},
		props: {
			backgroundData: Object
		},
		data() {
			return {
				// weird: we upload to httpbin and then actually upload it to firebase at the same time, super hacky, but works 
				// if we don't do this, we will not see progress update
				// dropzone sucks, we should look for a more lean solution, at some ponit
				dropzoneOptions: {
				  url: "https://httpbin.org/post",
				  autoProcessQueue: true,
				  thumbnailWidth: 340,
				  maxFiles: 1,
				  thumbnailHeight: 150,
				  maxFilesize: 10,
				  allowMultiple: false,
				  addRemoveLinks:  true,
				  acceptedFiles: ".jpg, .jpeg, .png",
				  dictDefaultMessage: `			<div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
				  <div class="space-y-1 text-center">
					<svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
					  <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
					<div class="flex text-sm text-gray-600">
					  <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
						<span>Upload a file</span>
						<input id="file-upload" name="file-upload" type="file" class="sr-only">
					  </label>
					  <p class="pl-1">or drag and drop</p>
					</div>
					<p class="text-xs text-gray-500">
					  PNG, JPG up to 10MB
					</p>
				  </div>
				</div>
	
					`
				}
			}
		},
		methods: {
			// triggered when an image has been dragged or selected 
			async uploadStared(upload) {
			
			  var imageName = uuid.v1();
			  this.isLoading = true;
			  try {
				let file = upload;
				var metadata = {
				  contentType: "image/png"
				};
				var storageRef = firebase.storage().ref();
				var imageRef = storageRef.child(`bgImage/${imageName}.png`);
				await imageRef.put(file, metadata);
				var downloadURL = await imageRef.getDownloadURL();
				this.backgroundData.imageURL = downloadURL;
				var brightness = await this.$imageBrightness(downloadURL);
				if(brightness > 120) {
					this.backgroundData.dotColor = "#333333"	
				} else {
					this.backgroundData.dotColor = "#CCCCCC"
				}
				this.$emit("changed");
			  } catch (error) {
				console.log(error);
			  }
			}
		}
	}
</script>

<style> 
	.vue-dropzone {
		border: none!important
	}
</style>
