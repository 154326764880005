var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col flex-grow md:flex-row",class:{ 'flex-col-reverse': !_vm.shouldShowUpsell || _vm.user.loggedIn }},[_c('div',{staticClass:"md:border-gray-200 justify-start",class:{
      'md:max-w-2xl': _vm.shouldShowUpsell && !_vm.user.loggedIn,
      'md:justify-center': _vm.shouldShowUpsell && !_vm.user.loggedIn,
      'rounded-md md:shadow absolute bottom-5 max-h-20 flex-none py-5 left-5 collapsed bg-white': !_vm.showControls,
      'flex flex-col flex-grow bg-white md:max-w-xl md:border-r relative': _vm.showControls
    }},[(_vm.shouldShowUpsell && !_vm.user.loggedIn)?_c('i',{staticClass:"fa fa-times-circle close text-gray-400",on:{"click":_vm.handleClose}}):_vm._e(),_c('promo',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldShowUpsell && !_vm.user.loggedIn),expression:"shouldShowUpsell && !user.loggedIn"}],staticClass:"md:m-auto",on:{"hidePromo":_vm.handleHidePromo}}),_c('controls',{directives:[{name:"show",rawName:"v-show",value:(!_vm.shouldShowUpsell || _vm.user.loggedIn),expression:"!shouldShowUpsell || user.loggedIn"}],attrs:{"playerSettings":_vm.playerSettings,"showControls":_vm.showControls},on:{"updateShowControls":_vm.handleShowControls}})],1),_c('Player',{staticClass:"md:block flex-grow h-72 md:h-auto",class:{
      hidden: _vm.shouldShowUpsell && !_vm.user.loggedIn,
      'md:bg-gray-200': _vm.shouldShowUpsell && !_vm.user.loggedIn,
    },attrs:{"playerSettings":_vm.playerSettings}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }