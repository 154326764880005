<template>
    <localViewer></localViewer>
</template>

<script>
// @ is an alias to /src
import LocalViewer from '@/components/LocalViewer.vue'

export default {
  name: 'Home',
  components: {
    LocalViewer
  }
}
</script>
