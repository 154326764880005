import plansData from './plans.json'

// returns plan object based on the provided locale with 
// extra property localePrice that is a string which write the price in
// the way the locale is used to 
export default function getPlan(locale, duration) {
    var environment = process.env.NODE_ENV

    var data = plansData[environment] 
    if (!data) { throw Error("no plans data for current environment")}

    var plan = data["default"][duration] 
    var isMonthly = (duration == "monthly")

    // if a local plan is available, use that 
    if(data[locale] != undefined) {
        plan = data[locale][duration]
    }

    // add a correctly formatted price string to the object
    plan.localePrice = Intl.NumberFormat(
        locale, 
        {style: "currency", currency: plan.currency}
        ).format(plan.amount/100) 
        
        
    // calculate monthly price for yearly plan 
    plan.monthlyPrice = isMonthly ? plan.localePrice : Intl.NumberFormat(
        locale, 
        {style: "currency", currency: plan.currency}
        ).format(plan.amount/100/12) 
    
        
    plan.isMonthly = isMonthly;

    return plan 
}