<template>
    <div class="stroop-wrapper">
        <span v-for="item in items" v-bind:style="{ 'color': item.color }" class="stroop-word"> 
            {{item.title}} 
        </span>
    </div>
</template>

<script>
export default {
    name: 'Stroop',
    props: {
        items: undefined
    }
}
</script>

 <style scoped>

.stroop-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    flex-wrap: wrap;
}

.stroop-word {
    display: block;
    width: 25%;
    height: 25%;
    font-size: 1.6em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}
    
.remote-host  .stroop-wrapper {
    height: 200px;
    width: 400px;
    flex-wrap: wrap;
}

.remote-host  .stroop-word {
    font-size: 1.2em; 
}
    
@media only screen and (max-width: 640px) {

    .stroop-wrapper {
        width: 100%; 
        height: 260px;
        padding-top: 30px;

    }
    
    .stroop-wrapper .stroop-word {
        width: 50%;
        height: 12%;
        font-size: 1em;
    }
}

</style>
