import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../components/Login.vue'
import Register from '../components/Register.vue'
import Account from '../components/Account.vue'
import store from './../store';
import firebase from "firebase";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/reset-password',
    name: 'reset-password', 
    component: () => import('../components/ResetPassword.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
   path: '/pro',
   alias: '/register',
   component: Register
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/',
    name: 'home',
    alias: '/home',
    component: Home
  },
  {
    path: '/host/:id?',
    name: 'host', 
    component: () => import('../components/RemoteHost.vue'),
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/session/:id',
    name: 'session', 
    component: () => import('../components/RemoteViewer.vue'),
  },
  {
  path: '/about', 
  beforeEnter(to, from, next) {
      window.location = "https://docs.cloudemdr.com"
    }
  },
  {
    path: '/help', 
    beforeEnter(to, from, next) {
        window.location = "https://docs.cloudemdr.com/guides/"
      }
  },
  {
    path: '/terms',
    name: 'terms',
    alias: ['/Terms_of_Service.pdf'],
    beforeEnter(to, from, next) {
      window.location = "https://docs.cloudemdr.com/about/terms-of-service.html"
    }

  },
  {
    path: '/privacy',
    name: 'privacy',
    alias: ['/Privacy_Policy.pdf'],
    beforeEnter(to, from, next) {
      window.location = "https://docs.cloudemdr.com/about/privacy-policy.html"
    }
},
  {
    path: '/remote', 
    name: 'remote',
    component: () => import('../views/Remote.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})


// check if there's a user present before allowing users to access restriced pages
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.user.loggedIn) {
      next({
        path: '/login',
        query: { redirect: to.path }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.onError(error => {
  // incase we have an error that contains something about chuncks failing, we retry
  if (/loading chunk \d* failed./i.test(error.message) && window.location.hash !== '#retry') {
    window.location.hash = '#retry'
    window.location.reload()
  }
})

export default router

