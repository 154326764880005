<template>
    <div class="min-h-full bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 flex-grow">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Log in
        </h2>
      </div>
          
      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Warning v-show="this.error" v-bind:isError=true :content="this.error" class="mb-4"> </Warning>
          <Warning v-if="$route.query.redirect" class="mb-2"> You need to login or create an account to continue </Warning>

          
          <form class="space-y-6" @submit.prevent="submit">

            <div> 
              <label for="email" class="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div class="mt-1">
                <input id="email" name="email" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" v-model="form.email" autofocus>
              </div>
            </div>
            
            <div>
              <label for="password" class="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div class="mt-1">
                <input id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" v-model="form.password">
              </div>
            </div>
            
    
    
            <div>
              <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Sign In
              </button>
            </div>
            
            <div class="flex items-center">
              <div class="text-sm">
                <router-link to="reset-password" class="font-medium text-indigo-600 hover:text-indigo-500">
                  Forgot your password?
                </router-link>
              </div>
            </div>

          </form>
          <p class="text-gray-400 mt-4"> No account yet? <router-link to="register" class="text-blue-600"> Sign up </router-link> </p>
          <p class="text-gray-400"> If you have trouble signing in, reach out to <a href="MAILTO:support@cloudemdr.com" class="text-blue-600">support</a>.</p>

        </div>
      </div>
    </div>

</template>

<script>
import { mapGetters } from "vuex";
import Warning from '@/components/Warning.vue'

export default {
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      error: undefined,
      nextRoute: null 
    };
  },
  components: {
    Warning
  },
  methods: {
    async submit() {
      try { 
        await this.$auth.login(this.form.email, this.form.password)
      } catch(error) {
        this.error = error.message
      }
    },
    async nextPage() {
      const destination = this.nextRoute || {name: "account"};

        if(this.$route.name != destination.name) {
          try {
            await this.$router.push(destination) 
          } catch(err) {
            throw new Error(`Error routing after login: ${err} with route ${destination}`);
          }
        }

    }
  },
  computed: { 
    ...mapGetters({
      user: "user"
    })
  },
  mounted: function() {
    // on mounted set the nextroute 
    this.nextRoute = this.$route.query.redirect; 
  },
  watch: {
    'user.loggedIn': function(newValue, oldValue) {
      if(newValue == true) {
        this.nextPage() 
      }
    }
  } 
};
</script>

