<template>
  <div
    class="flex flex-col flex-grow md:flex-row"
    v-bind:class="{ 'flex-col-reverse': !shouldShowUpsell || user.loggedIn }"
  >
    <div
      class="md:border-gray-200 justify-start"
      v-bind:class="{
        'md:max-w-2xl': shouldShowUpsell && !user.loggedIn,
        'md:justify-center': shouldShowUpsell && !user.loggedIn,
        'rounded-md md:shadow absolute bottom-5 max-h-20 flex-none py-5 left-5 collapsed bg-white': !showControls,
        'flex flex-col flex-grow bg-white md:max-w-xl md:border-r relative': showControls
      }"
    >
      <i
        class="fa fa-times-circle close text-gray-400"
        @click="handleClose"
        v-if="shouldShowUpsell && !user.loggedIn"
      ></i>

      <promo
        v-show="shouldShowUpsell && !user.loggedIn"
        class="md:m-auto"
        @hidePromo="handleHidePromo"
      >
      </promo>

      <controls
        v-show="!shouldShowUpsell || user.loggedIn"
        v-bind:playerSettings="playerSettings"
        :showControls="showControls"
        @updateShowControls="handleShowControls"
      >
      </controls>
    </div>

    <Player
      v-bind:playerSettings="playerSettings"
      class="md:block flex-grow h-72 md:h-auto"
      v-bind:class="{
        hidden: shouldShowUpsell && !user.loggedIn,
        'md:bg-gray-200': shouldShowUpsell && !user.loggedIn,
      }"
    >
    </Player>
  </div>
</template>

<script>
import Player from "@/components/player/Player.vue";
import Controls from "@/components/player/Controls.vue";
import Promo from "@/components/Promo.vue";
import { mapGetters } from "vuex";

export default {
  name: "LocalViewer",
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  data() {
    return {
      showControls: true,
      playerSettings: {
        isPlaying: false,
        isRemote: false,
        size: 60,
        speed: 10,
        sound: "none",
        color: "#333333",
        movement: "emdr-linear",
        background: "light",
        dotStyle: "noStyle",
        dotContent: "",
        stroopWords: [],
        mode: "dot",
      },
      shouldShowUpsell: true,
    };
  },
  components: {
    Player,
    Controls,
    Promo,
  },
  methods: {
    signUp() {
      sa_event("home_signup_tapped");
      this.$router.push({ name: "register" });
    },
    handleShowControls(newValue) {
      this.showControls = newValue;
    },
    handleClose() {
      sa_event("promo_closed");
      this.handleHidePromo();
    },
    handleHidePromo() {
      // event tracking happens in the component itself
      this.shouldShowUpsell = false;
    },
  },
};
</script>
<style>
.collapsed { 
  z-index: 100!important;
}

.collapsed .remote {
  margin-top: -26px !important;
  margin-left: -10px;
}

.close {
  top: 10px;
  right: 10px;
  position: absolute;
  font-size: 20pt;
  z-index: 100;
  cursor: pointer;
}

.close:hover {
  color: #000;
}
</style>
