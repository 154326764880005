<template>
    <div  v-bind:class="{ 'player-container': playerSettings.mode == 'dot'}" id="player-container"> 
        <div v-show="playerSettings.mode == 'stroop'" class="stroop-player" id="stroop-player"> 
            <stroop v-bind:items="playerSettings.stroopWords"></stroop>
        </div>
        <div v-show="playerSettings.mode == 'dot'" class="dot-player" id="dot-player"> 
        <div id="circle" :style="cssVars"> {{playerSettings.dotContent}} </div>
        </div>
        <span class="hidden md:block text-xs text-gray-300 absolute bottom-2 right-2"> <i class="fa fa-lock"> </i> Secure SSL connection - 100% Private </span>
    </div>
</template>

<script> 
require('howler');
import anime from 'animejs'
import Stroop from "@/components/player/Stroop.vue"

export default {

    name: 'Player',
    components: {
        Stroop
    },
    props: {
        playerSettings: Object,
        isRemotePlayer: false
    },
    computed: {
        cirlceSize: function () {
            // the remote viewer is smaller, hence gets a smaller dot. 
            if (this.playerSettings.isRemote) {
                return this.playerSettings.size/2;
            } 
            return this.playerSettings.size; 
        },
        cssVars() {
            return {
                'height' : (this.cirlceSize) + 'px',
                'width' : (this.cirlceSize) + 'px'
            }
        }
    },
    mounted: function() {

        window.addEventListener('focus', this.handleFocus);

        // create audioplayer to bypass stupid safari gezeik (dont preload to not get bandwith usage)
        this.audioPlayer = new Howl({src: ['../../assets/sounds/01-EMDR.mp3'], preload: false})

        if(this.isPlaying) {
            this.startPlayer()
        }
    },
    data() {
        return {
            audioPlayer: undefined,
            animationPlayer: undefined
        }
    },
    beforeDestroy: function() {
        // stop all audio when the component is destroyed
        if(this.audioPlayer != undefined) {
            this.audioPlayer.unload()
        }
        anime.remove("#circle")
        window.removeEventListener('focus', this.handleFocus);
    },
    watch: {
        // to support many browsers
        // changing speed or size needs to trigger an animation update 
        'playerSettings.speed': function(newValue, oldValue) {
            this.updateSpeed()
        },
        'playerSettings.size': function(newValue, oldValue) {
            this.updateSize()
        },
        'playerSettings.isPlaying': function(newValue, oldValue) {
            if(newValue == false) {
                this.stopPlayer()
            } else {
                this.startPlayer()
            }
        },
        'playerSettings.mode': function() {
            this.stopPlayer()
        },
        'playerSettings.movement': function() {
            this.setupAnimation() 
            if (this.playerSettings.isPlaying) {
                this.animationPlayer.play()
            }
        },
        'playerSettings.color': function(newValue, oldValue) {
            var circle = document.getElementById("circle");
            circle.style.backgroundColor = newValue;
        },
        'playerSettings.background': function(newValue, oldValue) {
            var player = document.getElementById("player-container");

            
            switch(newValue) {
                case "dark": 
                    player.style.backgroundImage = "none";
                    player.style.backgroundColor = "#000"
                    break; 
                case "light": 
                    player.style.backgroundImage = "none";
                    player.style.backgroundColor = "#fff"
                    break; 
                case "hospital": 
                    var bgUrl = require("../../assets/img/backgrounds/hospital.jpg");
                    player.style.backgroundImage = `url('${bgUrl}')`;
                    player.style.backgroundSize = "cover";
                    break; 
                case "forest": 
                    var bgUrl = require("../../assets/img/backgrounds/forest.jpg");
                    player.style.backgroundImage = `url('${bgUrl}')`;
                    player.style.backgroundSize = "cover";
                    break; 
                case "practice":
                    var bgUrl = require("../../assets/img/backgrounds/practice.jpg");
                    player.style.backgroundImage = `url('${bgUrl}')`;
                    player.style.backgroundSize = "cover";
                    break; 
                case "mountains":
                    var bgUrl = require("../../assets/img/backgrounds/mountains.jpg");
                    player.style.backgroundImage = `url('${bgUrl}')`;
                    player.style.backgroundSize = "cover";
                    break; 
                case "beach":
                    var bgUrl = require("../../assets/img/backgrounds/beach.jpg");
                    player.style.backgroundImage = `url('${bgUrl}')`;
                    player.style.backgroundSize = "cover";
                    break; 
                case "upload": 
                    break; 
                default: 
                // the default scenario is an URL 
                    var bgUrl = newValue
                    player.style.backgroundImage = `url('${bgUrl}')`;
                    player.style.backgroundSize = "cover";
                    break;
            }
            
        },
        'playerSettings.stroopColor': function(newValue, oldValue) {
            var word = document.getElementById("stroop-text"); 
            word.style.color = newValue;
        },
        'playerSettings.sound': function(newValue, oldValue) {
            // when the audio change we are stopping the playback

            if (newValue != "none") {
                var soundURL = require('../../assets/sounds/'+this.playerSettings.sound+'.mp3')
                
                if(this.audioPlayer != undefined) {
                    this.audioPlayer.unload()
                }

                this.audioPlayer = new Howl({
                    src: [soundURL], 
                    autoplay: false,
                    loop: true, 
                    volume: 1,
                    preload: true
                })


                if (this.playerSettings.isPlaying) {
                    this.audioPlayer.play()
                }
            } else {
                if (this.playerSettings.isPlaying && this.audioPlayer != undefined) {
                    this.audioPlayer.pause()
                }
            }
             
        }
    },
    methods: { 
        updateSize() {
            // showing and hiding the object will force all browser to re-render it, when you change the size
            // this is sometimes necessary to recalculate the keyframes
            var circle = document.getElementById("circle");
            circle.style.display = "none";
            setTimeout(() => circle.style.display = "block", 0);
        },
        stopPlayer() {
            if (this.animationPlayer != undefined) {
                this.animationPlayer.pause()
            }
            if(this.audioPlayer != undefined) {
                this.audioPlayer.pause()
            }
        },
        startPlayer() {

            if (this.animationPlayer != undefined) {
                this.animationPlayer.play()
            } else {
                this.setupAnimation()
                this.updateSpeed()
                this.animationPlayer.play()
            }
            if(this.audioPlayer != undefined && this.playerSettings.sound != "none") {
                this.audioPlayer.play()
            }
        },
        updateSpeed() {
            anime.speed = (this.playerSettings.speed/10) 
        },
        randomPlayer() {
            var self = this     
            this.animationPlayer = anime({
               targets: '#circle',
                        keyframes: [
                            {left: `20%`, top: `20%`,  translateX: 0, translateY: 0, translateZ: 0, duration: 0 }, // left top init position
                            {left: anime.random(50,100)+`%`, top: anime.random(50,100)+`%`,  translateX: `-100%`, translateY: `-100%`, translateZ: 0 }, // right bottom
                            {left: anime.random(50,100)+`%`, top: anime.random(0,50)+`%`,   translateX: `-100%`, translateY: 0, translateZ: 0 }, // right top rare jump hier
                            {left: anime.random(0,50)+`%`, top: anime.random(50,100)+`%`,  translateX: 0, translateY: `-100%`, translateZ: 0}, // left bottom 
                            {left: `20%`, top: `20%`,  translateX: 0, translateY: 0 }, // left top 
                        ],
                        direction: 'normal',
                        delay: 0,
                        duration: 3000,
                        easing: 'easeInOutSine',
                        autoplay: false,
                        complete: function() {
                            if(self.playerSettings.isPlaying && self.playerSettings.movement == "random") {
                                self.randomPlayer()
                                self.animationPlayer.play()
                            } 
                        }
                })

        },
        setupAnimation() { 
            anime.remove("#circle")

            if (this.playerSettings.movement == "emdr-linear") {
                this.animationPlayer = anime({
                    targets: '#circle',
                    keyframes: [
                        {top: `50%`, left: `0%`, translateX: `0%`, duration: 0, translateY: `-50%`, translateZ: 0},
                        {top: `50%`, left: `100%`, translateX: `-100%`, translateY: `-50%`, translateZ: 0},
                        {top: `50%`, left: `0%`, translateX: `0%`, translateY: `-50%`, translateZ: 0},
                    ],
                    loop: true, 
                    direction: 'alternate',
                    delay: 0,
                    duration: 3000,
                    easing: 'easeInOutSine',
                    autoplay: false
                })
            } else if (this.playerSettings.movement == "emdr-non-linear") {
                this.animationPlayer = anime({
                    targets: '#circle',
                    keyframes: [
                        {left: `0%`, top: `0%`,  translateX: 0, translateY: 0, duration: 0, translateZ: 0}, // left top init position
                        {left: `100%`, top: `100%`,  translateX: `-100%`, translateY: `-100%`, translateZ: 0}, // right bottom
                        {left: `100%`, top: `0%`,   translateX: `-100%`, translateY: 0, translateZ: 0}, // right top rare jump hier
                        {left: `0%`, top: `100%`,  translateX: 0, translateY: `-100%`, translateZ: 0}, // left bottom 
                        {left: `0%`, top: `0%`,  translateX: 0, translateY: 0, translateZ: 0}, // left top 
                    ],

                    loop: true, 
                    direction: 'normal',
                    delay: 0,
                    duration: 3000,
                    easing: 'easeInOutSine',
                    autoplay: false
                })
            } else if (this.playerSettings.movement == "diamond") {
                this.animationPlayer = anime({
                    targets: '#circle',
                    keyframes: [
                        {top: `0%`, left: `50%`, translateX: `-50%`, duration: 0, translateY: `0`, translateZ: 0},
                        {top: `50%`, left: `100%`, translateX: `-100%`, translateY: `-50%`, translateZ: 0},
                        {top: `100%`, left: `50%`, translateX: `-50%`, translateY: `-100%`, translateZ: 0},
                        {top: `50%`, left: `0%`, translateX: `0%`, translateY: `-50%`, translateZ: 0},
                        {top: `0%`, left: `50%`, translateX: `-50%`, translateY: `0`, translateZ: 0},
                    ],
                    loop: true, 
                    direction: 'normal',
                    delay: 0,
                    duration: 3000,
                    easing: 'easeInOutSine',
                    autoplay: false
                })
            } else if (this.playerSettings.movement == "diagonal") {
                this.animationPlayer = anime({
                    targets: '#circle',
                    keyframes: [
                        {top: `0%`, left: `0%`, translateX: `0%`, duration: 0, translateY: `0%`, translateZ: 0},
                        {top: `94%`, left: `100%`, translateX: `-100%`, translateY: `-100%`, translateZ: 0},
                        {top: `0%`, left: `0%`, translateX: `0%`, translateY: `0%`, translateZ: 0},
                    ],
                    loop: true, 
                    direction: 'normal',
                    delay: 0,
                    duration: 3000,
                    easing: 'easeInOutSine',
                    autoplay: false
                })
            } else if (this.playerSettings.movement == "diagonal-flipped") {
                this.animationPlayer = anime({
                    targets: '#circle',
                    keyframes: [
                        {top: `94%`, left: `0%`, translateX: `0%`, translateY: `-100%`, translateZ: 0, duration: 0},
                        {top: `0%`, left: `100%`, translateX: `-100%`, translateY: `0%`, translateZ: 0},
                        {top: `94%`, left: `0%`, translateX: `0%`, translateY: `-100%`, translateZ: 0},
                    ],
                    loop: true, 
                    direction: 'normal',
                    delay: 0,
                    duration: 3000,
                    easing: 'easeInOutSine',
                    autoplay: false
                })
            } else if (this.playerSettings.movement == "random") {
                this.randomPlayer()
            }
        },
        handleFocus() {
            // when focus has been lost/regained on the viewer side, the animation 
            // should reset, so we know for sure that the right values are set. 
            if(this.isRemotePlayer) {
                this.setupAnimation()
                this.updateSpeed()

                if(this.playerSettings.isPlaying) {
                    this.animationPlayer.play()
                } else {
                    if(this.animationPlayer != undefined) {
                        this.animationPlayer.pause()
                    }
                }
            }
        }
    }
}
</script>


<style>
.stroop-player {    
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    flex-direction: column;
}


.player-container {
    padding: 20px;
}

.dot-player {
    position: relative; 
    height: 100%;
}

#circle {
    display: block;
    top: 50%;
    left: 50%;
    width: var(--size);
    height: var(--size);
    background-color: #333333;
    border-radius: 50%;
    transform: translate3d(-50%,-50%,0);
    will-change: top, left, transform;
    transition-property: top, left, transform;
    position: absolute;
    
    /* content */
    color: #fff;
    text-align: center; 
    font-size: 22px;
    justify-content: center;
    display: flex;
    align-content: center;
    flex-direction: column;

}


</style> 